import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  paymentLoading: false,
  success: false,
  boletoGenerated: false,
  creditCardPaid: false,
  creditCardError: false,
  pixGenerated: false,
  isRenewPayment: false,
  availablePaymentMethods: [],
  changePaymentMethod: false,
  signaturePaymentData: null,
  newPlanOptions: [],
  showKuppiBoostDiscounts: false
};

export default function signaturePayment(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@signaturePayment/VERIFY_TOKEN_REQUEST': {
        draft.loading = true;
        draft.success = false;
        draft.isRenewPayment = false;
        draft.availablePaymentMethods = [];
        draft.signaturePaymentData = null;
        draft.boletoGenerated = false;
        draft.creditCardPaid = false;
        draft.creditCardError = false;
        draft.pixGenerated = false;
        draft.changePaymentMethod = false;
        draft.newPlanOptions = [];
        draft.showKuppiBoostDiscounts = false;
        break;
      }
      case '@signaturePayment/VERIFY_TOKEN_SUCCESS': {
        draft.success = true;
        draft.loading = false;
        draft.changePaymentMethod = false;
        draft.isRenewPayment =
          action.payload.signaturePaymentData.isRenewPayment;
        draft.availablePaymentMethods = action.payload.signaturePaymentData.availablePaymentMethods;
        draft.signaturePaymentData =
          action.payload.signaturePaymentData.advertiserSubscription;
        draft.boletoGenerated =
          action.payload.signaturePaymentData.advertiserSubscription
            .payment_type === 'boleto' &&
          action.payload.signaturePaymentData.advertiserSubscription
            .processor_transaction_id != null;
        draft.pixGenerated =
          action.payload.signaturePaymentData.advertiserSubscription
            .payment_type === 'pix' &&
          action.payload.signaturePaymentData.advertiserSubscription
            .processor_transaction_id != null;
        draft.newPlanOptions =
          action.payload.signaturePaymentData.newPlanOptions
        draft.showKuppiBoostDiscounts = 
          action.payload.signaturePaymentData.showKuppiBoostDiscounts
        break;
      }
      case '@signaturePayment/VERIFY_TOKEN_FAILURE': {
        draft.success = false;
        draft.loading = false;
        draft.isRenewPayment = false;
        draft.availablePaymentMethods = [];
        draft.signaturePaymentData = null;
        draft.boletoGenerated = false;
        draft.creditCardPaid = false;
        draft.creditCardError = false;
        draft.pixGenerated = false;
        draft.changePaymentMethod = false;
        draft.newPlanOptions = [];
        draft.showKuppiBoostDiscounts = false;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_BOLETO_REQUEST': {
        draft.paymentLoading = true;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_BOLETO_SUCCESS': {
        draft.paymentLoading = false;
        draft.boletoGenerated = true;
        draft.signaturePaymentData = action.payload.signaturePaymentData;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_BOLETO_FAILURE': {
        draft.paymentLoading = false;
        draft.boletoGenerated = false;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_REQUEST': {
        draft.paymentLoading = true;
        draft.creditCardError = false;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_SUCCESS': {
        draft.paymentLoading = false;
        draft.creditCardPaid = true;
        draft.creditCardError = false;
        draft.signaturePaymentData =
          action.payload.creditCardPaymentSuccessData.advertiserSubscription;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_FAILURE': {
        draft.paymentLoading = false;
        draft.creditCardPaid = false;
        draft.creditCardError = true;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_PIX_REQUEST': {
        draft.paymentLoading = true;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_PIX_SUCCESS': {
        draft.paymentLoading = false;
        draft.pixGenerated = true;
        draft.signaturePaymentData = action.payload.signaturePaymentData;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_PIX_FAILURE': {
        draft.paymentLoading = false;
        draft.pixGenerated = false;
        break;
      }
      case '@signaturePayment/SET_CHANGE_PAYMENT_METHOD': {
        draft.changePaymentMethod = true;
        break;
      }
      default:
    }
  });
}
